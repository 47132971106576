<template>
  <span
    class="tree-expand custom-tree-node"
    :class="{ between: NODE.level !== 1 }"
  >
    <span class="tree-label" v-show="DATA.isEdit">
      <el-input
        class="edit"
        size="mini"
        autofocus
        v-model="DATA.name"
        :ref="'treeInput' + DATA.id"
        @click.stop.native="nodeEditFocus"
        @keyup.enter.stop.native="nodeEditPass(STORE, DATA, NODE)"
        @blur.stop="nodeEditPass(STORE, DATA, NODE)"
      ></el-input>
    </span>
    <span
      v-show="!DATA.isEdit"
      :class="[DATA.id == currentId ? 'tree-new tree-label' : 'tree-label']"
    >
      <span>{{ DATA.name }}</span>
    </span>
    <div
      class="add-btn"
      @click.stop="nodeAdd(STORE, DATA, NODE)"
      v-if="NODE.level === 1"
    >
      <img src="@/assets/img/information/add.png" />
      <span>添加</span>
    </div>
    <span class="tree-btn" v-show="!DATA.isEdit">
      <!-- <i v-if="isAuth('org-users-dept-add')" class="el-icon-plus" @click.stop="nodeAdd(STORE,DATA,NODE)"></i>
			<i v-if="isAuth('org-users-dept-edit')" class="el-icon-edit" @click.stop="nodeEdit(STORE,DATA,NODE)"></i>
			<i v-if="isAuth('org-users-dept-delete') && NODE.level !== 1" class="el-icon-delete" @click.stop="nodeDel(STORE,DATA,NODE)"></i> -->
      <!-- <i class="el-icon-plus" @click.stop="nodeAdd(STORE,DATA,NODE)"></i>-->
      <!-- <i
        v-if="NODE.level !== 1"
        class="el-icon-edit"
        @click.stop="nodeEdit(STORE, DATA, NODE)"
      ></i>
      <i
        v-if="NODE.level !== 1"
        class="el-icon-delete"
        @click.stop="nodeDel(STORE, DATA, NODE)"
      ></i> -->
      <span v-if="NODE.level !== 1">
        <el-button
          type="text"
          size="mini"
          @click.stop="nodeEdit(STORE, DATA, NODE)"
        >
          <img
            src="@/assets/img/information/edit.png"
            style="width: 14px; height: 14px"
          />
        </el-button>
        <el-button
          type="text"
          size="mini"
          @click.stop="nodeDel(STORE, DATA, NODE)"
        >
          <img
            src="@/assets/img/information/delete.png"
            style="width: 14px; height: 14px"
          />
        </el-button>
      </span>
    </span>
  </span>
</template>

<script>
export default {
  name: "treeExpand",
  props: ["NODE", "DATA", "STORE", "maxexpandId", "currentId"],
  methods: {
    init() {
      console.log(data, clickId);
    },
    nodeAdd(s, d, n) {
      //新增
      // console.log(s,d,n,'aaaaaa');
      this.$emit("nodeAdd", s, d, n);
      console.log(n.level);
    },
    nodeEdit(s, d, n) {
      //编辑
      console.log(this.DATA.name, "full", d.id);
      d.isEdit = true;
      this.$nextTick(() => {
        this.$refs["treeInput" + d.id].$refs.input.focus();
      });
      this.$forceUpdate();
    },
    nodeDel(s, d, n) {
      //删除
      this.$emit("nodeDel", s, d, n);
    },
    nodeEditPass(s, d, n) {
      //编辑完成
      console.log(this.DATA.name, "down");
      if (d.isEdit) {
        d.isEdit = false;
        this.$emit("nodeEdit", s, d, n);
      }
    },
    nodeEditFocus() {
      //阻止点击节点的事件冒泡
    },
  },
};
</script>

<style lang="scss" scope>

.tree-expand {
  overflow: hidden;
}
.tree-expand .tree-new {
  font-weight: 600;
  /* font-weight: normal; */
}
.tree-expand .tree-label {
  font-size: 0.9em;
}
.tree-expand .tree-label .edit {
  width: 80%;
}
.tree-expand .tree-btn {
  display: none;
  float: right;
  margin-right: 20px;
}
.tree-expand .tree-btn i {
  color: #8492a6;
  font-size: 0.9em;
  margin-right: 3px;
}
.add-btn {
  width: 56px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid #0099ff;
  font-size: 12px;
  color: #0099ff;
  display: flex;
  align-items: center;
  justify-content: center;
	line-height: 24px;
	margin-left: 16px;
  img {
    width: 14px;
    height: 14px;
    // vertical-align: middle;
  }
}
.custom-tree-node {
  width: 100%;
  display: inline-block;
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-right: 8px;
  &.between {
    justify-content: space-between;
  }
}
</style>
